import HqqRequest from "./axios"
// import Socket from "./socket"

// const HOST = "vip.xiaochi.pro"
// const POST = "80"
const HOST = "192.168.0.143"
// const HOST = "192.168.3.3"
const POST = "8080"
const BASE_URL = `${HOST}:${POST}`

const hqqRequest = new HqqRequest({
  baseURL: '',
  timeout: 300000,
  // 默认携带token
  isToken: true
})

// const socket = new Socket({
//   url: `ws://${BASE_URL}/socket`
// })

export { hqqRequest }
