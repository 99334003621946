export function localGet(key) {
  const value = window.localStorage.getItem(key)
  try {
    return JSON.parse(window.localStorage.getItem(key))
  } catch (error) {
    return value
  }
}

export function localSet(key, value) {
  window.localStorage.setItem(key, JSON.stringify(value))
}

export function localRemove(key) {
  window.localStorage.removeItem(key)
}

// 判断内容是否含有表情字符，现有数据库不支持。
export function hasEmoji(str = "") {
  const reg =
    /[^\u0020-\u007E\u00A0-\u00BE\u2E80-\uA4CF\uF900-\uFAFF\uFE30-\uFE4F\uFF00-\uFFEF\u0080-\u009F\u2000-\u201f\u2026\u2022\u20ac\r\n]/g
  return str.match(reg) && str.match(reg).length
}
export const ossdomain = "http://oss.xiaochi.pro/"
// 单张图片上传
export const uploadImgServer = "http://82.156.197.45:9090/api/upload/file"
// 多张图片上传
export const uploadImgsServer = "http://82.156.197.45:9090/api/upload/files"
export const domain = "http://vip.xiaochi.pro"
export const imgpath = "http://vip.xiaochi.pro/static/upload/"

export const pathMap = {
  index: "首页",
  groups: "商品分类",
  orders: "订单",
  order_detail: "订单详情",
  goods_detail: "商品详情",
  my: "个人中心",
  mobile: "账号绑定",
  faq: "常见问题",
  userinfo: "我的信息",
  recharge: "余额充值",
  withdrawal: "余额提现",
  vip: "开通会员",
  ttsq: "天天神券",
  record: "消费明细",
  create_order: "创建订单",
  couponlist: "优惠券"
}
