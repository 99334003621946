import { Module } from "vuex"
import { IrootState, IchatState, IchatRoom } from "../types"
import { hqqRequest } from "@/network"
import { getStorage, setStorage } from "@/utils/localStorage"
import router from "@/router"

const chatModule: Module<IchatState, IrootState> = {
  namespaced: true,
  state: {
    onLine: [],
    tourist: [],
    chatRooms: []
  },
  actions: {
    // 发送消息
    sendchatMessageAction(store, data) {
      console.log("sendchatMessageAction", data)
      // socket.send({ type: "sendchatMessage", data })
    },
    createRoomAction(store, data) {
      console.log("createRoom")

      // socket.send({ type: "createRoom", data })
    },
    reConnectionAction() {
      console.log("reConnectionAction")
      // socket.send({ type: "login" })
    },
    // 根据聊天室id获取聊天记录
    getChatListAction(store, roomId: number) {
      console.log(roomId, 123)
      // socket.send({ type: "getChatList", data: { roomId } })
    }
  },
  mutations: {
    getlocalRoomsAction(state) {
      let rooms
      // if (!getStorage("userInfo")?.token) {
      //   rooms = getStorage("youke_rooms")
      // } else {
      //   rooms = getStorage("rooms")
      // }
      rooms = getStorage("rooms")

      if (rooms instanceof Array) {
        state.chatRooms = rooms
      }
    },
    // 改变在线用户信息
    changeOnLine(state, data) {
      const { onLine, tourist } = data.onLineUsers
      state.onLine = onLine ?? []
      state.tourist = tourist ?? []
    },
    changeChatRooms(state, rooms: IchatRoom[]) {
      console.log(state, rooms, "changeChatRooms")
      state.chatRooms.forEach((item, index) => {
        const start = item.chats.length - 1
        // const indey = item.chats.findIndex((item2) => item2.isRead === 0)
        // if (indey !== -1) {
        //   start = indey - 1
        // }
        // 同步本地isRead状态
        // for (let i = start; i >= 0; i--) {
        //   if (rooms[index]?.chats[i]?.isRead !== undefined) {
        //     rooms[index].chats[i].isRead = 1
        //   }
        // }
      })
      state.chatRooms = rooms
      // if (!getStorage("userInfo")?.token) {
      //   return setStorage("youke_rooms", rooms)
      // }
      setStorage("rooms", rooms)
    },
    // 添加聊天室消息
    addChatMessage(state, data) {
      const room = state.chatRooms.find((item) => item.id === data.roomId)
      // data.createTime = new Date().toISOString()
      if (room) {
        room.chating = data.chating
        room.chats.push(data)
        setStorage("rooms", state.chatRooms)
        // data.token = "87aa5bdde5447e9e4d76bbf7d472bf2b"
        // hqqRequest
        //   .request({
        //     method: "post",
        //     url: "/api/chat",
        //     data,
        //     showLoading: {
        //       // text: "正在登录",
        //       // errorMessage: "账号或密码错误",
        //       // successMessage: "登录成功"
        //     }
        //   })
        //   .then((res) => {
        //     if (res.data.code == 200) {
        //       room.chats.push({ id: 1, message: res.data.data, role: "assistant", createTime: new Date().toISOString() })
        //       setStorage("rooms", state.chatRooms)
        //     }
        //   })
      } else {
        console.log(123)
        // socket.send({ type: "login" })
      }
      // room && room.chats.push(data)
    },
    changeIsRead(state, roomId) {
      const room = state.chatRooms.find((item) => item.id === roomId)
      console.log(room)
      console.log("changeIsRead")
      // if (room) {
      //   room.chats.forEach((item) => {
      //     item.isRead = 1
      //   })
      // }
      // if (!getStorage("userInfo")?.token) {
      //   return setStorage("youke_rooms", state.chatRooms)
      // }
      setStorage("rooms", state.chatRooms)
    },

    roomDetail(state, id) {
      router.push({
        path: "/chatDetail",
        query: {
          id
        }
      })
      // socket.send({ type: "login" })
      // state.chatRooms.push({ id: data.roomId, chats: [], name: data.name, img: data.img, tips: 0 })
    }
  }
  // getters: {
  //   tips(state) {
  //     let num = 0
  //     state.chatRooms.forEach((room) => {
  //       const n = room?.chats?.reduce((pre, cur) => {
  //         // return pre + (cur?.isRead === 0 ? 1 : 0)
  //       }, 0)
  //       room.tips = n
  //       num += n
  //     })

  //     return (num <= 99 ? num : "99+") || ""
  //   }
  // }
}

export default chatModule
