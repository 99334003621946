import { Module } from "vuex"

import { registerData } from "@/network/login/types"
import { IrootState, IloginState, IuserInfo } from "../types"

import { login, register, verifyToken } from "@/network/login"
import { getStorage, removeStorage } from "@/utils/localStorage"
// import { messageType } from "@/network/socket/types"

const loginModule: Module<IloginState, IrootState> = {
  namespaced: true,
  state: {},
  actions: {
    // 登录
    async loginAction(store, userInfo: registerData) {
      const res = await login({ username: userInfo.username, password: userInfo.password })
      if (res.status === 200) {
        store.commit("changeUserInfo", res.data, { root: true })
        store.dispatch("getlocalRoomsAction", null, { root: true })
        store.dispatch("initIp", null, { root: true })
        // 返回登录成功，让路由跳转
        return true
      }
    },

    // 注册
    async registerAction(_, userInfo: registerData) {
      await register(userInfo)
    },

    // 根据本地缓存登录
    async localLoginAction({ dispatch, commit }) {
      const userInfo = getStorage<IuserInfo>("userInfo")

      const res = await verifyToken()
      if (res.status === 200 && res.data.status === 200) {
        commit("changeUserInfo", res.data.data, { root: true })
        // dispatch("getlocalRoomsAction", null, { root: true })
      } else if (res.data.code === 419) {
        const noauth = ["/goods_detail", "/groups", "/ttsq"]
        if (!noauth.includes(location.pathname)) {
          const jurl = location.href
          const jhost = location.host
          removeStorage("userInfo")
          location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx993da9f7fecb42c9&redirect_uri=http://${jhost}/api/auth&response_type=code&scope=snsapi_userinfo&state=${jurl}#wechat_redirect`
        }
      }
    },

    // async localLoginAction({ dispatch, commit }) {
    //   const res = await verifyToken()

    //   if (res.status === 200) {
    //     // location.href = "https://vant-ui.github.io/vant/#/zh-CN/swipe"
    //     console.log(res, "hello")
    //     // commit("changeUserInfo", res.data.data, { root: true })
    //   }
    // },

    // 退出登录
    loginOutAction({ commit }) {
      commit("changeUserInfo", {}, { root: true })
    }
  },
  mutations: {}
}

export default loginModule
