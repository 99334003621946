import { defineComponent, ref } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "@/store";
import { pathMap } from "@/utils";
import "vant/lib/index.css";
import { setStorage } from "@/utils/localStorage";
export default defineComponent({
  name: "App",

  setup() {
    const animationName = ref("");
    const router = useRouter();
    router.beforeEach((to, from) => {
      // 进入样式
      if (to.path === "/chatDetail" || to.path === "/momentDetail" || to.path === "/changeInfo") {
        animationName.value = "chat-detail-enter";
      } else if (from.path === "/chatDetail" || from.path === "/momentDetail" || from.path === "/changeInfo") {
        // 退出样式
        animationName.value = "chat-detail-leave";
      } else {
        animationName.value = "";
      }

      if (to.path === "/monent" || to.path === "/chat" || to.path === "/my") {
        // 在主页面
        setStorage("quit", 1);
      } else {
        // 在子页面
        setStorage("quit", 0);
      }

      document.title = pathMap[to.name];
    });
    const store = useStore(); // const tips = computed(() => store.getters["chatModule/tips"])

    return {
      animationName // tips

    };
  }

});