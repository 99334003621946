import { createApp } from "vue"
import App from "./App.vue"

import router from "./router"
import store from "./store"
import { initLogin } from "./store"
import formatDate from "./global/formatDate"
import imgView from "./global/imgView"
import { Lazyload } from "vant"

// toast image-preview dialog 组件的样式
import "vant/es/toast/style"
import "vant/es/dialog/style"
import "vant/es/image-preview/style"
const orderStatus = {
  1: "待支付",
  2: "待发货",
  3: "交易成功",
  4: "已退款",
  5: "售后中",
  6: "售后完成",
  0: "订单已取消"
}
const app = createApp(App)
app.config.globalProperties.$filters = {
  orderMap(status) {
    return orderStatus[status] || "未知状态"
  }
}
app.use(router)
app.use(store)

initLogin()

app.use(formatDate)
app.use(imgView)
app.use(Lazyload)

app.mount("#app")
