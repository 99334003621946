import { createRouter, createWebHashHistory, createWebHistory } from "vue-router"
import { showDialog, showConfirmDialog } from "vant"
import { getStorage } from "@/utils/localStorage"
const routes = [
  {
    path: "/",
    redirect: "/index"
  },
  {
    path: "/index",
    name: "index",
    component: () => import("@/views/index/index.vue")
    // beforeEnter: (to, from) => {
    //   if(to.path === "/")
    //   return false
    // }
  },
  {
    path: "/goods_detail",
    name: "goods_detail",
    component: () => import("@/views/goods/goods.vue"),
    meta: {
      tabbarHidden: true
    }
  },
  {
    path: "/create_order",
    name: "create_order",
    component: () => import("@/views/goods/create_order.vue"),
    meta: {
      tabbarHidden: true
    }
  },
  {
    path: "/couponlist",
    name: "couponlist",
    component: () => import("@/views/my/couponlist.vue"),
    meta: {
      tabbarHidden: true
    }
  },
  {
    path: "/groups",
    name: "groups",
    component: () => import("@/views/groups/index.vue")
  },
  {
    path: "/orders",
    name: "orders",
    component: () => import("@/views/orders/orders.vue")
  },
  {
    path: "/order_detail",
    name: "order_detail",
    component: () => import("@/views/orders/detail.vue"),
    meta: {
      tabbarHidden: true
    }
  },

  {
    path: "/my",
    name: "my",
    component: () => import("@/views/my/my.vue")
  },
  {
    path: "/userinfo",
    name: "userinfo",
    component: () => import("@/views/my/userinfo.vue"),
    meta: {
      tabbarHidden: true
    }
  },
  {
    path: "/mobile",
    name: "mobile",
    component: () => import("@/views/my/mobile.vue"),
    meta: {
      tabbarHidden: true
    }
  },
  {
    path: "/withdrawal",
    name: "withdrawal",
    component: () => import("@/views/my/withdrawal.vue"),
    meta: {
      tabbarHidden: true
    }
  },
  {
    path: "/recharge",
    name: "recharge",
    component: () => import("@/views/my/recharge.vue"),
    meta: {
      tabbarHidden: true
    }
  },
  {
    path: "/record",
    name: "record",
    component: () => import("@/views/my/record.vue"),
    meta: {
      tabbarHidden: true
    }
  },
  {
    path: "/faq",
    name: "faq",
    component: () => import("@/views/my/faq.vue"),
    meta: {
      tabbarHidden: true
    }
  },
  {
    path: "/ttsq",
    name: "ttsq",
    component: () => import("@/views/mt/ttsq.vue"),
    meta: {
      tabbarHidden: true
    }
  },
  {
    path: "/vip",
    name: "vip",
    component: () => import("@/views/my/vip.vue"),
    meta: {
      tabbarHidden: true
    }
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach(async (to) => {
  document.title = "ChatGPT"
  if (to.path === "/changeInfo") {
    const userInfo = getStorage("userInfo")
    if (!userInfo?.id) {
      const res = await showConfirmDialog({ message: "未登录，是否前往登录页面？" })
      if (res) {
        return "login"
      }
      return false
    }
  }
  // if ((to.path === "/moment" || to.path === "/chat") && location.host != "127.0.0.1:8080") {
  //   showDialog({ message: "功能开发中，敬请期待。" })
  //   return false
  // }

  if (to.path === "/login") {
    const userInfo = getStorage("userInfo")
    if (userInfo?.token) {
      return false
    }
  }
})

router.onError(() => {
  router.go(-1)
})

export default router
